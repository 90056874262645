<template>
  <Loading />
  <n-config-provider :theme="darkTheme" :theme-overrides="themeOverrides">
    <n-loading-bar-provider>
      <n-message-provider :placement="messagePlacement">
        <n-notification-provider>
          <n-modal-provider>
            <n-dialog-provider>
              <ToastInjector />
              <template v-if="globalLoadingRef">
                <FullPageLoading />
              </template>
              <n-layout class="sign-up-layout" position="absolute">
                <n-layout-header style="height: 76px" bordered>
                  <SignUpStepsHeader />
                </n-layout-header>
                <n-layout has-sider>
                  <NavigationSidebar v-if="$layoutData.showMenu === true" />
                  <main class="main-wrapper" @click="collapseSidebar">
                    <slot />
                  </main>
                </n-layout>
              </n-layout>
            </n-dialog-provider>
          </n-modal-provider>
        </n-notification-provider>
      </n-message-provider>
    </n-loading-bar-provider>
  </n-config-provider>
</template>

<script setup lang="ts">
import { darkTheme } from 'naive-ui';
import type { NLayout } from 'naive-ui';
import { getSignUpThemeOverrides } from '../../base/utils/sign-up-theme-overrides';
import NavigationSidebar from '../components/sign-up/NavigationSidebar.vue';
import SignUpStepsHeader from '../components/sign-up/SignUpStepsHeader.vue';
import FullPageLoading from '../components/full-page-loading.vue';

const { sidebarCollapsedData: collapsed, setSidebarCollapsed } = useSidebarCollapse();
const { signUpStepsLayoutData: $layoutData } = useSignUpStepsLayout();

const themeOverrides = getSignUpThemeOverrides();

const collapseSidebar = () => {
  if (window.innerWidth < 768 && !collapsed.value) {
    setSidebarCollapsed(true);
  }
};

const signUpStore = useSignUpStore();

const globalLoadingRef: ComputedRef<boolean> = computed(() => signUpStore.globalLoading);
</script>

<style scoped lang="scss">
.sign-up-layout :deep(.n-layout .n-layout-scroll-container) {
  min-height: calc(100vh - 76px);
}

.main-wrapper {
  padding-bottom: 48px;
  width: 100%;
  max-height: calc(100% - 76px);
  background-color: $layout-background;
}
</style>
