<template>
  <n-modal v-model:show="show" :auto-focus="false" class="modal-component">
    <div class="main-wrapper">
      <div class="close-wrapper">
        <img src="../../assets/images/close.svg" alt="" @click="closeModal" />
      </div>
      <div class="content-wrapper">
        <div v-if="header" class="header">
          {{ header }}
        </div>
        <div v-if="description" class="decription">
          {{ description }}
        </div>
        <slot />
        <div class="actions-wrapper">
          <n-button v-if="cancel" round :disabled="cancel.disabled ?? false" @click="cancel.callback">{{
            cancel.text ?? 'Cancel'
          }}</n-button>
          <n-button type="primary" round :disabled="submit.disabled ?? false" @click="submit.callback">{{
            submit.text ?? 'Submit'
          }}</n-button>
        </div>
      </div>
    </div>
  </n-modal>
</template>

<script lang="ts" setup>
const show = ref<boolean>(false);

const { submit, cancel, header, description } = defineProps<{
  submit: {
    callback: () => void;
    text?: string;
    disabled?: boolean;
  };
  cancel?: {
    callback?: () => void;
    text?: string;
    disabled?: boolean;
  };
  header?: string;
  description?: string;
}>();

const openModal = () => {
  show.value = true;
};

const closeModal = () => {
  show.value = false;
};

defineExpose({ openModal, closeModal });
</script>

<style lang="scss" scoped>
.modal-component {
  border-radius: 16px;
  min-width: 320px;
}

.header {
  padding-bottom: 12px;
  text-align: center;
  font-size: 24px;
}

.decription {
  padding-bottom: 30px;
  font-size: 16px;
  text-align: center;
  color: $grey;
}

.main-wrapper {
  padding: 24px 24px 48px 24px;
  width: 566px;
  background-color: $dark-gray-2;
}

.close-wrapper {
  padding-bottom: 6px;
  width: 100%;
  text-align: right;

  img {
    cursor: pointer;
  }
}

.actions-wrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;

  .n-button {
    height: 47px;
    width: 180px !important;
  }
}

@media (max-width: 670px) {
  .main-wrapper {
    padding: 12px 12px 24px 12px;
  }
  .actions-wrapper {
    .n-button {
      width: 145px !important;
    }
  }
}

@media (max-width: 586px) {
  .modal-component {
    margin: 10px;
  }
}
</style>
