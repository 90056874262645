<script setup lang="ts"></script>

<template>
  <div class="sr-full-page-size-loading">
    <n-spin size="large" />
  </div>
</template>

<style scoped lang="scss">
.sr-full-page-size-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;

  z-index: 100;

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: $black_05;

  overflow: hidden;
}
</style>
