import type { GlobalThemeOverrides } from 'naive-ui';
import variables from '../assets/scss/config/variables.module.scss';

export const getSignUpThemeOverrides = (): GlobalThemeOverrides => {
  return {
    ...getSignUpButtonThemeOverrides(),
    ...getSignUpFormThemeOverrides(),
    ...getSignUpTooltipThemeOverrides(),
    ...getSignUpProgressThemeOverrides(),
    ...getSignUpConfirmModalThemeOverrides(),
    ...getMessageThemeOverrides(),
    Spin: {
      color: variables.primaryGreen,
    },
  };
};

export const getSignUpButtonThemeOverrides = (): GlobalThemeOverrides => {
  return {
    Button: {
      heightMedium: '48px',
      fontSizeLarge: '16px',
      color: variables.defaultGray,
      textColor: variables.white,
      colorPrimary: variables.primaryGreen,
      colorHoverPrimary: variables.primaryGreen,
      colorPressedPrimary: variables.primaryGreen,
      colorFocusPrimary: variables.primaryGreen,
      textColorPrimary: variables.white,
      textColorHoverPrimary: variables.white,
      textColorPressedPrimary: variables.white,
      textColorFocusPrimary: variables.white,
      textColorDisabledPrimary: variables.white,
      border: 0,
      borderHover: 0,
      borderPressed: 0,
      borderFocus: 0,
      borderDisabled: 0,
      rippleColor: variables.defaultGray,
      colorDisabledPrimary: variables.disabledPrimaryGreen,
      textColorHover: variables.white,
      borderPrimary: 0,
      borderHoverPrimary: 0,
      borderPressedPrimary: 0,
      borderFocusPrimary: 0,
      borderDisabledPrimary: 0,
      rippleColorPrimary: variables.primaryGreen,
      textColorPressed: variables.white,
      textColorFocus: variables.white,
      colorHover: variables.defaultGray,
      colorPressed: variables.defaultGray,
      colorFocus: variables.defaultGray,
      colorDisabled: variables.defaultGray,
      textColorDisabledInfo: variables.buttonInfoColor,
      textColorError: variables.white,
    },
  };
};

export const getSignUpFormThemeOverrides = (): GlobalThemeOverrides => {
  const textColor = variables.white;
  const labelTextColor = variables.lightGray2;
  const backgroundColor = variables.defaultGray;

  return {
    InternalSelection: {
      borderRadius: '8px',
      heightLarge: '48px',
      fontSizeMedium: '16px',
      color: backgroundColor,
      placeholderColor: labelTextColor,
    },
    Input: {
      textColor,
      color: backgroundColor,
      heightLarge: '48px',
      paddingLarge: '14px',
      caretColor: textColor,
      borderRadius: '8px',
      fontSizeLarge: '16px',
      placeholderColor: labelTextColor,
    },
    Form: {
      labelTextColor: labelTextColor,
      labelFontWeight: 500,
      labelFontSizeTopLarge: '16px',
      lineHeight: '22px',
    },
  };
};

export const getSignUpTooltipThemeOverrides = (): GlobalThemeOverrides => {
  return {
    Tooltip: {
      color: variables.lightGray2,
    },
  };
};

export const getSignUpNavigationStepperThemeOverrides = (): GlobalThemeOverrides => {
  const headerColor = variables.white;
  const descriptionColor = variables.menuInactive;
  const splitorColor = variables.lightGray2;
  const processColor = variables.mediumGreen;
  const finishColor = variables.mediumGreen;
  const waitColor = variables.darkGray2;

  return {
    Steps: {
      stepHeaderFontSizeMedium: '21px',
      headerTextColorProcess: headerColor,
      headerTextColorWait: headerColor,
      headerTextColorFinish: headerColor,
      headerTextColorError: headerColor,
      descriptionTextColorProcess: descriptionColor,
      descriptionTextColorWait: descriptionColor,
      descriptionTextColorFinish: descriptionColor,
      descriptionTextColorError: descriptionColor,
      splitorColorFinish: splitorColor,
      splitorColorWait: splitorColor,
      splitorColorProcess: splitorColor,
      indicatorBorderColorProcess: processColor,
      indicatorBorderColorFinish: finishColor,
      indicatorBorderColorWait: splitorColor,
      indicatorColorProcess: processColor,
      indicatorColorFinish: finishColor,
      indicatorColorWait: waitColor,
      indicatorSizeMedium: '32px',
    },
  };
};

export const getSignUpProgressThemeOverrides = (): GlobalThemeOverrides => {
  return {
    Progress: {
      fillColor: variables.lightGreen2,
    },
  };
};

export const getSignUpConfirmModalThemeOverrides = (): GlobalThemeOverrides => {
  return {
    Dialog: {
      closeSize: '30px',
      closeIconColor: variables.white,
      closeIconColorHover: variables.errorColor,
      closeBorderRadius: '.25rem',
      closeMargin: '.5rem .5rem 0 0',
      padding: 0,
      color: variables.defaultGray,
      borderRadius: '12px',
    },
  };
};
