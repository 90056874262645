<template>
  <n-config-provider :theme-overrides="themeOverrides">
    <n-layout-sider
      bordered
      collapse-mode="width"
      class="sidebar-wrapper"
      :collapsed-width="0"
      :width="sidebarWidth"
      :collapsed="collapsed"
      :native-scrollbar="false"
      @collapse="collapsed = true"
    >
      <n-layout-content position="absolute" :native-scrollbar="false" :theme-overrides="{ color: 'transparent' }">
        <div class="wrapper">
          <div class="header">
            Welcome
            <img src="../../assets/images/hand.png" alt="" />
          </div>
          <div class="description">{{ appName }} is fast, simple, and secure</div>
          <div class="stepper-wrapper">
            <NavigationStepper
              :navigation="navigation"
              :statuses="signUpStore.sidebarPointsStatusMap"
              :current="signUpStore.sidebarCurrentPoint"
            />
          </div>
        </div>
      </n-layout-content>
    </n-layout-sider>
  </n-config-provider>
</template>

<script lang="ts" setup>
import debounce from 'debounce';
import { getSignUpNavigationStepperThemeOverrides } from '../../../base/utils/sign-up-theme-overrides';
import NavigationStepper from './NavigationStepper.vue';
import { AccountAppType } from '../../common/enums/account-app-type';
import {
  PATH_SIGN_UP_ACCOUNT_PERSONAL,
  PATH_SIGN_UP_BANK_INFO,
  PATH_SIGN_UP_BENEFICIAL_OWNERS_INFO,
  PATH_SIGN_UP_BUSINESS_INFO,
  PATH_SIGN_UP_CONTROLLER_INFO,
  PATH_SIGN_UP_DOCUMENT,
  PATH_SIGN_UP_LOCATION_PERSONAL,
  PATH_SIGN_UP_PAPER_CHECK_INFO,
  PATH_SIGN_UP_RETRY_BUSINESS,
  PATH_SIGN_UP_RETRY_BUSINESS_SOLE_PROPRIETORSHIP,
  PATH_SIGN_UP_RETRY_PERSONAL,
} from '../../common/constants/paths';
import type { NavigationPointWithoutStatus } from '../../common/sign-up/navigation-point';
import { BusinessTypes } from '../../common/sign-up/common/business-type';
import { NavigationStatus } from '../../common/enums/navigation-status';
import { DwollaCustomerType } from '../../common/enums/dwolla-customer-type';

const themeOverrides = getSignUpNavigationStepperThemeOverrides();

const { data: $auth } = useAuth();
const route = useRoute();
const { sidebarCollapsedData: collapsed, setSidebarCollapsed } = useSidebarCollapse();
const { signUpStepsLayoutData: $layoutData } = useSignUpStepsLayout();
const signUpStore = useSignUpStore();

const collapsedWidth = ref<number>(104);
const sidebarWidth = ref<number>(357);
const sidebarPosition = ref<'static' | 'absolute'>('static');
const isAgentSnap = computed(() => $layoutData.value.accountAppType === AccountAppType.AgentSnap);
const appName = computed(() => (isAgentSnap.value ? 'AgentSnap' : 'ClaimsSnap'));

const isDocumentPointVisible = computed<boolean>(() => isSignUpDocumentRequired(signUpStore.requirements));

const isBOPointLocked = computed<boolean>(() => !signUpStore.beneficialOwnersInfo.isCompleteAvailable);

const isRetryPersonalPointVisible = computed<boolean>(() => isSignUpRetryPersonalRequired(signUpStore.requirements));
const isRetryBusinessSoleProprietorshipPointVisible = computed<boolean>(() =>
  isSignUpRetryBusinessSoleProprietorshipRequired(signUpStore.requirements),
);
const isRetryBusinessPointVisible = computed<boolean>(() => isSignUpRetryBusinessRequired(signUpStore.requirements));

const isNavigationLocked = computed(() =>
  [
    isRetryPersonalPointVisible.value,
    isRetryBusinessSoleProprietorshipPointVisible.value,
    isRetryBusinessPointVisible.value,
    isDocumentPointVisible.value,
    isBOPointLocked.value,
  ].some(Boolean),
);

const isBusiness = computed<boolean>(() => Boolean($auth.value && $auth.value.type === DwollaCustomerType.Business));
const isControllerInfoPointVisible = computed<boolean>(() =>
  Boolean(signUpStore.businessInfo.type && signUpStore.businessInfo.type !== BusinessTypes.SoleProprietorship),
);
const isBoInfoPointVisible = computed<boolean>(() => Boolean(!isAgentSnap.value && isControllerInfoPointVisible.value));

const handleResize = (): void => {
  const isDesktop = window.innerWidth >= 768;

  collapsedWidth.value = isDesktop ? 104 : 0;
  sidebarWidth.value = isDesktop ? 357 : 321;
  sidebarPosition.value = isDesktop ? 'static' : 'absolute';

  setSidebarCollapsed(!(window.innerWidth >= 1200));
};

const resizeEventHandler = debounce(handleResize, 50);

const handle = (index: number, { url: path, disabled }: NavigationPointWithoutStatus) => {
  if (disabled) {
    return;
  }

  signUpStore.sidebarPointsStatusMap[route.path] = NavigationStatus.Finish;
  signUpStore.sidebarPointsStatusMap[path] = NavigationStatus.Process;
  signUpStore.sidebarCurrentPoint = index;

  navigateTo({ path }, { redirectCode: 302 });
};

const navigation = ref<NavigationPointWithoutStatus[]>([
  ...(!$auth.value
    ? [
        {
          title: 'Account Info',
          description: `Creating your ${appName.value} login`,
          onClick: handle,
          url: PATH_SIGN_UP_ACCOUNT_PERSONAL,
          disabled: isNavigationLocked.value,
        },
      ]
    : []),
  ...(signUpStore.isSender
    ? [
        {
          title: 'Location Info',
          description: `Collecting your personal address info`,
          onClick: handle,
          url: PATH_SIGN_UP_LOCATION_PERSONAL,
          disabled: isNavigationLocked.value,
        },
        ...(isRetryPersonalPointVisible.value
          ? [
              {
                title: 'Retry Personal Info',
                description: `Please re-enter your personal info`,
                onClick: handle,
                url: PATH_SIGN_UP_RETRY_PERSONAL,
                disabled: isNavigationLocked.value,
              },
            ]
          : []),
        ...(isBusiness.value
          ? [
              {
                title: 'Business Info',
                description: 'Collecting your business’s address info',
                onClick: handle,
                url: PATH_SIGN_UP_BUSINESS_INFO,
                disabled: isNavigationLocked.value,
              },
            ]
          : []),
        ...(isRetryBusinessSoleProprietorshipPointVisible.value
          ? [
              {
                title: 'Retry Business Info',
                description: `Please re-enter your business info`,
                onClick: handle,
                url: PATH_SIGN_UP_RETRY_BUSINESS_SOLE_PROPRIETORSHIP,
                disabled: isNavigationLocked.value,
              },
            ]
          : []),
        ...(isRetryBusinessPointVisible.value
          ? [
              {
                title: 'Retry Business Info',
                description: `Please re-enter your business info`,
                onClick: handle,
                url: PATH_SIGN_UP_RETRY_BUSINESS,
                disabled: isNavigationLocked.value,
              },
            ]
          : []),
        ...(isBusiness.value && isControllerInfoPointVisible.value
          ? [
              {
                title: 'Company Controller Info',
                description: 'Gathering details about a financial controller of your business',
                onClick: handle,
                url: PATH_SIGN_UP_CONTROLLER_INFO,
                disabled: isNavigationLocked.value,
              },
            ]
          : []),
        ...(isDocumentPointVisible.value
          ? [
              {
                title: 'Document verification',
                description: 'Verifying your paperwork',
                onClick: handle,
                url: PATH_SIGN_UP_DOCUMENT,
                disabled: isNavigationLocked.value,
              },
            ]
          : []),
        ...(isBoInfoPointVisible.value
          ? [
              {
                title: 'Beneficial Owner Info',
                description: 'Listing your business’s largest owners',
                onClick: handle,
                url: PATH_SIGN_UP_BENEFICIAL_OWNERS_INFO,
                disabled: isNavigationLocked.value,
              },
            ]
          : []),
        {
          title: 'Bank Info',
          description: `So ${appName.value} can automate your payments`,
          onClick: handle,
          url: PATH_SIGN_UP_BANK_INFO,
          disabled: isNavigationLocked.value,
        },
        ...(isBusiness.value
          ? [
              {
                title: 'Paper Check Setup',
                description: `So ${appName.value} can remit net premiums on your behalf with mailed checks`,
                onClick: handle,
                url: PATH_SIGN_UP_PAPER_CHECK_INFO,
                disabled: isNavigationLocked.value,
              },
            ]
          : []),
      ]
    : []),
]);

watch(
  () => navigation,
  () => {
    signUpStore.sidebarNavigationPoints = navigation.value;
  },
);

onMounted(async () => {
  if (isBusiness.value) {
    await Promise.all([signUpStore.fetchBusinessInfo(), signUpStore.fetchBeneficialOwnersInfo()]);
  }
  signUpStore.sidebarNavigationPoints = navigation.value;
  handleResize();
  window.addEventListener('resize', resizeEventHandler);
});

onUnmounted(() => {
  window.removeEventListener('resize', resizeEventHandler);
});
</script>

<style scoped lang="scss">
.wrapper {
  padding: 10px 25px 10px 25px;

  @media (min-width: 769px) {
    padding: 20px 35px 20px 35px;
  }
}

.stepper-wrapper {
  margin: 15px 0 15px 0;
}

.header {
  margin-bottom: 5px;
  font-size: 24px;
  color: $white;
}

.description {
  margin-bottom: 10px;
  font-size: 16px;
  color: $menu-inactive;
}

.sidebar-wrapper {
  height: 100%;
  background-color: $dark-gray-2;
}
</style>
