<template>
  <n-steps vertical>
    <template #finish-icon>
      <div class="point finish"></div>
    </template>
    <template v-for="(item, index) in steps" :key="index">
      <n-step
        :title="item.title"
        :description="item.description"
        :status="item.status"
        :disabled="item.disabled || item.status === NavigationStatus.Wait"
        @click="(item.status !== NavigationStatus.Wait || index === current) && item.onClick(index, item)"
      >
        <template #icon>
          <i class="n-base-icon">
            <div :class="getPointClasses(item.status)"></div>
          </i>
        </template>
      </n-step>
    </template>
  </n-steps>
</template>

<script lang="ts" setup>
import type { NavigationPoint, NavigationPointWithoutStatus } from '../../common/sign-up/navigation-point';
import { NavigationStatus } from '../../common/enums/navigation-status';

const props = defineProps<{
  navigation: NavigationPointWithoutStatus[];
  statuses: Record<string, NavigationStatus>;
  current: number;
}>();

const steps = computed<NavigationPoint[]>(() =>
  props.navigation.map((item) => ({
    ...item,
    status: props.statuses[item.url] ?? NavigationStatus.Wait,
  })),
);

const getPointClasses = (status: NavigationStatus) => {
  return {
    point: true,
    process: status === NavigationStatus.Process,
    wait: status === NavigationStatus.Wait,
  };
};
</script>

<style scoped lang="scss">
:deep(.n-step):not(:has(.point.process), .n-step--disabled) {
  cursor: pointer;
}

.point {
  width: 16px;
  height: 16px;
  margin: 1px auto 1px auto;
  text-align: center;
  border-radius: 50%;
  border: 0;
}

.wait {
  background-color: $light-gray-2;
}

.test {
  background-color: red;
}

.finish {
  background-color: white;
}

.process {
  background-color: white;
  animation: pulse-animation 1s infinite;
  animation-direction: alternate;
}

@keyframes pulse-animation {
  0% {
    box-shadow:
      0 0 0 10px $medium-green,
      0 0 0 10px $dark-green-2;
  }
  100% {
    box-shadow:
      0 0 0 10px $medium-green,
      0 0 0 15px $dark-green-2;
  }
}
</style>
